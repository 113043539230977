<template>
  <form
    autocomplete="off"
    @submit.prevent="submit"
  >
    <div class="row">
      <loading-notification :show="loading" />
      <error-notification :show="noMatch && isNew">
        <span>{{ $t('users.tabs.password.no_match') }}</span>
      </error-notification>
      <error-notification :show="isError.district && submitted">
        <span>{{ $t('users.tabs.form.no_district') }}</span>
      </error-notification>
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md6">
        <va-date-picker
          id="birthdate-input"
          :label="$t('users.inputs.birthdateInput')"
          :config="dateConfig"
          v-model="userBirthdate"
          required
        />
      </div>
    </div>

    <div
      class="row"
      v-if="isNew"
    >
      <div
        class="flex xs12"
        :class="{'md6': currentUser.can('Users', 'roles')}"
        v-if="currentUser.can('Users', 'groups')"
      >
        <va-select
          v-model="selectedGroup"
          :label="$t('layout.form.groupInput')"
          :options="groupsList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          :loading="isLoading.groups"
          text-by="name"
          searchable
        />
      </div>
      <div
        class="flex xs12"
        :class="{'md6': currentUser.can('Users', 'groups')}"
        v-if="currentUser.can('Users', 'roles')"
      >
        <va-select
          v-model="selectedRole"
          :label="$t('users.inputs.roleInput')"
          :options="rolesList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          :loading="isLoading.roles"
          text-by="name"
          searchable
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          type="email"
          v-model="form.email.value"
          :disabled="loading"
          :label="$t('layout.form.emailInput')"
          :error="!!form.email.errors.length"
          :error-messages="translatedErrors(form.email.errors)"
          @blur="validate('email')"
        />
      </div>
    </div>
    <user-contacts
      ref="userContacts"
      class="flex xs12"
      :loading="loading"
      :user="user"
      :country="selectedCountry"
    />

    <location-bar
      :loading="loading"
      :with-filters="locationFilters"
      :initial-district="initialDistrict"
      :initial-country="initialCountry"
      @selected-country="adjustCountry"
      @selected-district="adjustDistrict"
    />

    <div
      class="row"
      v-if="isNew"
    >
      <div class="flex xs12">
        <va-checkbox
          v-model="emailNotify"
          class="mb-0"
        >
          <template slot="label">
            {{ $t('auth.user_email_notify') }}
          </template>
        </va-checkbox>
      </div>
      <div class="flex xs12">
        <va-checkbox
          v-model="activateCredentials"
          class="mb-0"
        >
          <template slot="label">
            {{ $t('auth.user_with_credentials') }}
          </template>
        </va-checkbox>
      </div>
      <div class="flex xs12 md6" v-show="activateCredentials">
        <va-input
          color="info"
          v-model="form.password.value"
          :type="passwordVisible ? 'text' : 'password'"
          :label="$t('auth.password')"
          :error="!!form.password.errors.length"
          :error-messages="translatedErrors(form.password.errors)"
          @blur="validate('password')"
        >
          <va-button
            type="button"
            slot="append"
            style="margin-right: 0;"
            :icon="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
            @click="showPassword()"
            flat
            small
          />
        </va-input>
      </div>
      <div class="flex xs12 md6" v-show="activateCredentials">
        <va-input
          color="info"
          v-model="form.confirm_password.value"
          :type="passwordVisible ? 'text' : 'password'"
          :label="$t('users.inputs.confirmPasswordInput')"
          :error="!!form.confirm_password.errors.length"
          :error-messages="translatedErrors(form.confirm_password.errors)"
          @blur="validate('confirm_password')"
        >
          <va-button
            type="button"
            slot="append"
            style="margin-right: 0;"
            :icon="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
            @click="showPassword()"
            flat
            small
          />
        </va-input>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          type="submit"
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const UserContacts = () => import(/* webpackPrefetch: true */ './UserContacts')
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')

export default {
  name: 'users-form',
  components: {
    LocationBar,
    UserContacts,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    user: {
      type: Object,
      required: false,
      default: function () { return { id: 0 } },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    noMatch () {
      return this.newPassword !== this.confirmPassword && this.confirmPassword.length > 0
    },
    isNew () {
      return this.user.id === 0
    },
  },
  data () {
    return {
      dateConfig: {},
      submitted: false,
      isLoading: {
        groups: false,
        roles: false,
      },
      isError: {
        groups: false,
        roles: false,
        district: true,
      },
      emailNotify: true,
      activateCredentials: false,
      passwordVisible: false,
      newPassword: '',
      confirmPassword: '',
      userBirthdate: null,
      groupsList: [],
      locationFilters: ['regions', 'countries', 'districts'],
      initialDistrict: null,
      initialCountry: null,
      selectedDistrict: null,
      selectedCountry: null,
      selectedGroup: '',
      rolesList: [],
      selectedRole: '',
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        email: {
          value: '',
          validate: {
            required: false,
          },
          errors: [],
        },
        password: {
          value: '',
          validate: {
            required: false,
            minLength: 8,
          },
          errors: [],
        },
        confirm_password: {
          value: '',
          validate: {
            required: false,
            minLength: 8,
            equalsTo: {
              field: 'password',
              msg: '',
            },
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    activateCredentials (val) {
      this.form.email.validate.required = !!val
      this.form.password.validate.required = !!val
      this.form.confirm_password.validate.required = !!val
    },
    user (val) {
      this.setUser(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setUser (val) {
      this.setFormData(val)
      if (val.district) {
        this.selectedDistrict = val.district
        this.initialDistrict = this.selectedDistrict
      }

      if (val.country) {
        this.selectedCountry = val.country
        if (!this.initialDistrict) {
          this.initialCountry = this.selectedCountry
        }
      }

      if (val.group) {
        this.selectedGroup = val.group
        this.selectedGroup.alias = this.selectedGroup.name
      }

      if (val.birthdate) {
        this.userBirthdate = val.birthdate
      }

      if (val.id) {
        await this.$nextTick()
        delete this.form.password
        delete this.form.confirm_password
        this.validateAll()
      }
    },
    showPassword () {
      this.passwordVisible = !this.passwordVisible
    },
    isEmpty (field) {
      let empty = field === null
      if (!empty && !Number.isFinite(field)) {
        empty = field.length === 0
      }
      return empty
    },
    initialData () {
      if (this.user) {
        this.setUser(this.user)
      }

      if (this.currentUser.can('Users', 'groups')) {
        this.asyncFindGroup('&sort=name&direction=asc')
      }
      if (this.currentUser.can('Users', 'roles')) {
        this.asyncFindRole('')
      }
    },
    async asyncFindGroup (query) {
      if (this.currentUser.can('Users', 'groups')) {
        await this.asyncFind(query, 'groups', 'users/groups')
        if (this.isError.groups) {
          this.asyncFindGroup(query)
        }
        this.groupsList.forEach(w => {

          w.name = this.$t('groups.' + w.name)

        })
      }
    },
    async asyncFindRole (query) {
      if (this.currentUser.can('Users', 'roles')) {
        await this.asyncFind(query, 'roles', 'users/roles')
        if (this.isError.roles) {
          this.asyncFindRole(query)
        }
        this.rolesList.forEach(rol => {
          rol.name = this.$t(rol.name) || rol.name
        })
      }
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    adjustCountry (country) {
      this.selectedCountry = country
    },
    adjustDistrict (district) {
      this.selectedDistrict = district
      this.isError.district = false
    },
    async hasErrors () {
      this.submitted = true
      this.isError.district = false
      if (!this.formReady) return true
      // if (!this.selectedDistrict) {
      //   this.isError.district = true
      //   this.showToast(this.$t('notifications.errors.district_needed'), {
      //     icon: 'fa-times',
      //     position: 'top-right',
      //   })
      //   return true
      // }
      return false
    },
    async submit () {
      this.validateAll()
      const hasErrors = await this.hasErrors()
      if (hasErrors) return

      const user = this.getFormData(this.user)
      delete user.country
      delete user.district
      delete user.church
      delete user.group
      delete user.created
      delete user.modified
      delete user.groups
      delete user.dcpi_role
      delete user.team

      if (this.selectedRole) {
        user.dcpi_role_id = this.selectedRole.id
      }
      if (this.selectedCountry) {
        user.country_id = this.selectedCountry.id
      }
      if (this.selectedDistrict) {
        user.district_id = this.selectedDistrict.id
      }
      if (this.userBirthdate) {
        user.birthdate = this.userBirthdate
      }

      const contacts = this.$refs.userContacts.submit()
      if (contacts) {
        user.user_contacts = contacts.slice(0)
      }

      if (this.isNew) {
        let emailLang = 'en'
        if (this.emailNotify) {
          const { value: lang } = await this.$swal.fire({
            icon: 'info',
            title: this.$t('auth.confirm_email_lang'),
            input: 'select',
            inputOptions: {
              esp: 'Español',
              eng: 'English',
              por: 'Portuguese',
            },
            inputPlaceholder: this.$t('layout.selects.select_a_language'),
            showCancelButton: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                let msg = null
                if (value === '') {
                  msg = this.$t('layout.forms.language_needed')
                }
                resolve(msg)
              })
            },
          })

          // console.log(lang)
          if (lang) {
            emailLang = lang
          } else {
            await this.$swal.fire({
              icon: 'error',
              title: this.$t('auth.language_needed_for_email'),
            })
            return
          }
        }
        user.emailData = {
          notify: this.emailNotify,
          lang: emailLang,
        }
        if (this.selectedGroup) {
          user.groups = {
            _ids: [this.selectedGroup.id],
          }
        }
        const password = this.getFormValue('password')
        user.password = this.isEmpty(password) || !this.activateCredentials ? '*' : password
        delete user.id
      }

      const properties = Object.keys(user)
      for (const p in properties) {
        if (user[properties[p]] === null) {
          delete user[properties[p]]
        }
      }

      this.$emit('submit', user)
    },
  },
}
</script>
